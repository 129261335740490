:root {
  --scale: .5;
  --primary-color: #313238;
  --secondary-color: #E59400;
  --reverse-secondary: #00495E;
  --complimentary-secondary: #4500e5;
  --secondary-rgb: rgb(229, 148, 0);
  --tertiary-color: #9999A1;

  --c1: #313238;
  --c2: #F96900;
  --c3: #a9b4c2;
  --c4: #eef1ef;
  --c5: #bb0a21;

  --contrast: .7;
  --uncontrast: 1.4;

  --popup-br: 8px;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
}

.logo-container {
  margin: 24px;
  height: 120px;
  width: 340px;
  display: block;
  margin: 24px auto;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.header-blur {
  background-color: rgba(0, 0, 0, 0.5);

  /*background-image: linear-gradient(rgba(0,0,0,0), rgba(229, 148, 0,.4));*/

  border-radius: 8px;
  backdrop-filter: blur(5px);
}

.logo {
  height: 80px;
  margin: 8px;
}

.sub-t {
  color: #9999A1;
  margin-left: 18px;
  font-size: 27px;
  font-style: bold;
  margin-top: -21px;

  padding-bottom: 8px;
}

.leaflet-container {
  position: absolute;

  width: 100%;
  height: 100vh;

  filter: contrast(.7);

  background-color: white;
}

.markercluster-map {
  height: 100vh;
  z-index: -1;
}

.box-shadow {
  width: 100%;
  height: 100vh;

  box-shadow: inset 0 0 126px 5px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}

.arial {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: bold;
}


/*marker cluster style*/
.marker-cluster-small {
  background-color: rgba(148, 148, 148, 0.0);
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, .8));
}

.marker-cluster-small div {
  background-color: rgba(224, 224, 224, 0.8);
}

.marker-cluster-small:hover {
  background-color: rgba(211, 211, 211, 0.4);
}

.map-icon {
  filter: contrast(10) saturate(0) invert(1);
  /*filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));*/
}

.map-icon:hover {
  filter: contrast(var(--uncontrast)) drop-shadow(3px 3px 2px rgba(0, 0, 0, .25));
  
}

/* POPUP */
.leaflet-popup {
  width: 300px;
  height: 400px;
}

.leaflet-popup-content-wrapper {
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.5);

  /*background-image: linear-gradient(rgba(0,0,0,0), rgba(229, 148, 0,.4));*/

  border-radius: var(--popup-br);
  backdrop-filter: blur(5px);

  
}

.leaflet-popup-content {
    width:100% !important;
    height:100%;

    margin: 0px;

    display: grid;
    grid-template-columns: 100%;
    column-gap: 10px;
}

.leaflet-popup-tip-container {
  height: 0px;
}

#api-frame {
  filter: contrast(var(--uncontrast));

  height: 100%;
  width: 100%;

  border-radius: var(--popup-br) var(--popup-br) 0px 0px;

  border: 0px;
}

.leaflet-popup-content h2 {
  text-align: center;
}


@media only screen and (min-width: 500px) {
  .logo-container {
    margin: 24px;
    height: 120px;
    width: 340px;
    float: left;
  
  }
}
@media only screen and (min-width: 850px) {
  .leaflet-popup {
    width: 800px;
    height: 400px;
  }

  .leaflet-popup-content {
    width:100% !important;
    height:100%;

    margin: 0px;

    display: grid;
    grid-template-columns: 61.8% 38.2%;
    column-gap: 10px;
  }

  #api-frame {
    filter: contrast(var(--uncontrast));

    height: 100%;
    width: 100%;

    border-radius: var(--popup-br) 0px 0px var(--popup-br);

    border: 0px;
  }

  .leaflet-popup-content h2 {
    text-align: left;
  }
} 